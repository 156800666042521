import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/miniiwiki.mn-frontend/components/Header.jsx");
;
import(/* webpackMode: "eager" */ "/root/miniiwiki.mn-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/root/miniiwiki.mn-frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"cyrillic-ext\",\"cyrillic\",\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/root/miniiwiki.mn-frontend/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Carlito-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Carlito-Italic.ttf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/Carlito-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Carlito-BoldItalic.ttf\",\"weight\":\"700\",\"style\":\"italic\"}],\"variable\":\"--font-carlito\"}],\"variableName\":\"carlito\"}");
;
import(/* webpackMode: "eager" */ "/root/miniiwiki.mn-frontend/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Mulish-ExtraLight.ttf\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Mulish-ExtraLightItalic.ttf\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/Mulish-Light.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Mulish-LightItalic.ttf\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/Mulish-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Mulish-Italic.ttf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/Mulish-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Mulish-MediumItalic.ttf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/Mulish-SemiBold.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Mulish-SemiBoldItalic.ttf\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/Mulish-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Mulish-BoldItalic.ttf\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/Mulish-ExtraBold.ttf\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Mulish-ExtraBoldItalic.ttf\",\"weight\":\"800\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/Mulish-Black.ttf\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Mulish-BlackItalic.ttf\",\"weight\":\"900\",\"style\":\"italic\"}],\"variable\":\"--font-mulish\"}],\"variableName\":\"mulish\"}");
;
import(/* webpackMode: "eager" */ "/root/miniiwiki.mn-frontend/app/globals.css");
